// (Admin Panel) Color scheme
$font-color: black;
$separator-color: black;
$header-background: white;
$body-background: white;
$link-border: grey;

// (CRUD Table) Image size
$table-image-size: 80px;

// (Uploads) Image display size
$uploads-image-size: 150px;
