@import "../../vars";

#widgets{
  display: flex;
  flex-wrap: wrap;

  .widget{
    text-align: center;
    margin: 0 10px 25px 10px;
    width: calc(50% - 20px);
    font-size: 0.8em;

    img{
      height: $widgetImageSmall;
      width: $widgetImageSmall;
      margin-bottom: 5px;
    }
  }
}

#logout{
  text-align: center;
  padding-bottom: 20px;
}

@media(min-width: $bpMedium) {
  #widgets .widget{
    width: calc(33% - 20px);
  }
}

@media(min-width: $bpLarge) {
  #widgets .widget {
    font-size: 1em;
    margin-bottom: 70px;
    img{
      height: $widgetImageLarge;
      width: $widgetImageLarge;
    }
  }
}
