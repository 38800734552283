@import "vars";

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
  outline: 0;
}
