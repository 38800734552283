@import "../../vars";

.grecaptcha-badge { visibility: hidden; }

#form {
  padding: 20px;
  min-height: 100%;
  & > div {
    margin-bottom: $section-margin;
  }
  .title {
    font-size: 1.1em;
  }
  .errors {
    background-color: rgba(255, 0, 0, 0.5);
    padding: 10px;
    & a {
      font-weight: bold;
    }
  }
  .inputs table {
    width: 100%;
    tr td {
      padding-bottom: $input-margin;
    }
    tr:last-child td {
      padding-bottom: 0;
    }
    .icon {
      display: none;
      width: 1px;
      padding-right: $input-icon-padding-right;
      img {
        height: $input-icon-height;
      }
    }
    .content {
      input, textarea, select, div label {
        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
        width: 100%;
        font-size: 1.3em;
        border: 0;
        color: inherit;
        background-color: transparent;
        box-shadow: none;
      }
      input, .add-border {
        border-bottom: $input-line;
        padding-bottom: 5px;
      }
      textarea, select {
        border: $input-line;
        padding: 5px;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
      }
      input[type="radio"], input[type="checkbox"] {
        width: auto;
      }
      div input[type="checkbox"]{
        margin-right: 20px;
        transform: scale(1.1);
      }
    }
  }
  .buttons {
    button {
      background-color: transparent;
      color: inherit;
      cursor: pointer;
    }
    a {
      display: inline-block;
      &.underline {
        text-decoration: underline;
        padding: 0 0.3rem;
      }
    }
    button, a:not(.underline) {
      font-size: 1.1em;
      border: 3px solid white;
      border-radius: 1em;
      padding: 10px 20px;
      margin: 0 30px 10px 0;
    }
  }
  .links a {
    display: block;
    text-align: center;
    font-style: italic;
    font-size: 1.2em;
    margin-bottom: 8px;
  }
  .recaptcha-wrapper {
    button[type="submit"] {
      margin-top: 1rem;
    }
    .recaptcha-v2 {
      margin-top: 1rem;
    }
  }
}

@media(min-width: $bpMedium) {
  #form .inputs table .icon {
    display: table-cell;
  }
}

// Fix for React Datepicker
.react-datepicker__time-list {
  padding-left: 0;
  li {
    padding-right: 47px !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  div, input {
    width: 100%;
  }
}
