// Colors
$lightblue: #4B6B7A;
$gutters: #19161A;

// Breakpoints
$bpMedium: 300px;
$bpLarge: 768px;

// Text
$font: sans-serif;
$color: $lightblue;

// Header image sizes
$headerImageSmall: 100px;
$headerImageMedium: 125px;
$headerImageLarge: 150px;

// Forms
$section-margin: 40px;
$input-line: 3px solid white;
$input-margin: 30px;
$input-icon-height: 40px;
$input-icon-padding-right: 50px;

// Widget image size
$widgetImageSmall: 40px;
$widgetImageLarge: 50px;
