@import "../vars";

#main {
  font-family: $font;
  color: $color;
  background-color: $gutters;
  height: 100%;

  .invert{
    color: white;
    background-color: $lightblue;
  }

  #content {
    height: 100%;
    max-width: $bpLarge;
    margin: 0 auto;
    background: url("../img/bg.jpg") center top;
    overflow: auto;
    display: flex;
    flex-direction: column;

    #header {
      text-align: center;
      padding: 1rem;

      #image, #title {
        margin-bottom: 5px;
      }

      #image img {
        max-width: 90%;
      }

      .profile-img{
        border: 5px solid white;
        box-shadow: 0 0 5px 1px black;
        height: $headerImageSmall;
      }

      #title {
        font-size: 1.2em;
      }

      #subtitle {
        font-size: 1em;
      }
    }
    #body{
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

@media(min-width: $bpMedium) {
  #main #content {
    #header {
      .profile-img{
        height: $headerImageMedium;
      }
      #title{
        font-size: 1.4em;
      }

      #subtitle{
        font-size: 1.1em;
      }
    }
  }
}

@media(min-width: $bpLarge) {
  #main #content {
    #header{
      .profile-img{
        height: $headerImageLarge;
      }
      #title{
        font-size: 1.6em;
      }

      #subtitle{
        font-size: 1.2em;
      }
    }
  }
}
