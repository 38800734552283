#contact-info{
  .item{
    display: flex;
    padding: 15px;
    margin-bottom: 25px;
    line-height: 1.8em;

    .image{
      flex: 1;
      text-align: center;
      img{
        height: 35px;
      }
    }

    .text{
      flex: 5;
      border-bottom: 3px solid white;
      font-size: 1.2em;
    }
  }
}