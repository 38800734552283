@import "vars";

#admin-panel {
  height: 100%;
  background-color: $body-background;
  font-family: sans-serif;
  color: $font-color;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  #header {
    background-color: $header-background;
    border-bottom: 3px solid $separator-color;
    text-align: center;
    font-size: 2em;
    padding: 20px;
  }

  #wrapper {
    flex-grow: 1;
    display: flex;
    overflow: auto;

    #sidebar {
      flex-basis: 20%;
      overflow: auto;
      padding: 15px;
      border-right: 2px solid $separator-color;

      a {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.1);
        border: 2px solid $link-border;
        border-radius: 0.75em;
        margin-bottom: 15px;
        padding: 10px;
      }

      a:hover, .active {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    #body {
      flex: 1;
      padding: 15px;
      overflow: auto;
    }
  }
}

#admin-crud-table, #admin-join-table, #email-log-table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    padding: 10px;
  }

  th {
    background-color: rgba(0, 0, 0, 0.2);
  }

  td {
    vertical-align: top;
  }

  .shrink {
    width: 1px;
  }
}

#admin-crud-table {
  textarea, img {
    height: 100%;
  }

  textarea {
    width: 100%;
    min-width: 150px;
    resize: none;
    background-color: transparent;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    border: 0;
    padding: 5px 10px;
    border-radius: 0.5em;
  }

  textarea:focus {
    background-color: rgba(0, 0, 0, 0.5);
  }

  textarea:disabled {
    color: grey;
  }

  img, .img-placeholder {
    cursor: pointer;
    height: $table-image-size;
    width: $table-image-size;
    font-style: italic;
  }

  .action {
    color: grey;
  }

  .delete, .create {
    font-weight: bold;
    cursor: pointer;
  }

  .delete {
    color: red;
  }

  .create {
    color: green;
  }
}

#admin-join-table {
  input[type="checkbox"] {
    outline: 0;
  }
}

#admin-uploads {
  height: 100%;

  .dropzone {
    border: 1px solid black;
    padding: 10px;
    border-radius: 0.5em;
    min-height: 25%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  #current-files > div {
    width: $uploads-image-size;
    position: relative;
    display: inline-block;
    margin: 20px;

    img {
      width: $uploads-image-size;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;

      button {
        height: 30px;
        background: transparent;
        padding: 0 5px;
        border: 0;
        color: red;
        font-weight: bold;
        font-size: 1.8em;
        border-radius: 1em;
        cursor: pointer;
      }
    }
  }

  button {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 1em;
    padding: 10px;
    color: inherit;
    font-size: 1.2rem;
    margin-right: 10px;
    cursor: pointer;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#view-preview {
  button {
    width: 196px;
    height: 36px;
    font-size: 1.4em;
  }

  text-align: center;
}

#preview {
  select {
    font-size: 1.2em;
    padding: 6px 2px;
  }

  #widget-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;

    .widget-icon {
      flex: 0 0 33%;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;

      .position {
        flex: 1;
        margin: auto;
        text-align: center;
        font-size: 2em;
      }

      .icon-title {
        flex: 4;

        img {
          width: 42px;
        }
      }
    }
  }
}

#list-main {
  display: flex;

  .section {
    padding: 1rem;
  }

  button {
    cursor: pointer;
    color: white;
    border: none;
    font-size: 2em;
    line-height: 0;
    border-radius: 6px;
  }

  .section.submission {
    flex: 1;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      input {
        width: 100%;
        height: 40px;
        padding: 5px 10px;
        margin: 8px 0;
      }

      button {
        width: 100%;
        height: 32px;
        margin-top: 12px;
        background-color: #408000;
      }
    }
  }

  .section.list {
    flex: 2;
    .item {
      display: flex;
      margin: 8px 0;

      button {
        height: 32px;
        width: 32px;
        margin-right: 8px;
        background-color: #d70206;
      }

      div {
        padding: 0.3rem;
      }
    }
  }
}